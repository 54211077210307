import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { useIntl } from 'react-intl'
import { InView } from 'react-intersection-observer'

import Video from '../components/Video'
import media from '../styles/mq'
import GalleryBlock from '../components/blocks/GalleryBlock'
import SideIndex from '../components/SideIndex'
import useInView from '../hooks/useInView'

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
  grid-gap: var(--space-medium);
  ${media.lessThan('medium')} {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
`

const VideoWrapper = styled.div`
  h3 {
    font-size: var(--font-size-medium);
    margin-top: var(--space-small);
    margin-bottom: var(--space-smaller);
  }
`

const Quote = styled.div`
  position: relative;
  margin-bottom: var(--space-large);
  :not(:last-child) {
    padding-bottom: var(--space-medium);
    border-bottom: 1px solid var(--color-grey);
  }
  q {
    display: block;
    font-size: var(--font-size-medium);
    margin-bottom: var(--space-small);
    line-height: 1.8;
  }
  .source {
    font-size: var(--font-size-small);
  }
`

const MediaPage = ({
  data: {
    dato: { page, allVideos }
  }
}) => {
  const { gallery, press } = page
  const { formatMessage: t } = useIntl()
  const [{ video: firstVideo }, ...videos] = allVideos

  const { offsets, handleInView, inViewIds } = useInView()

  const titles = useMemo(
    () =>
      ['videos', 'images', 'press'].map(id => ({
        title: t({ id }),
        id
      })),
    [t]
  )

  return (
    <>
      <SideIndex titles={titles} offsets={offsets} inViewIds={inViewIds} />
      <InView
        threshold={0.1}
        onChange={(inView, entry) => handleInView('videos', inView, entry)}
      >
        <h1 className="c-large">{t({ id: 'videos' })}</h1>
        <div className="c-large">
          <VideoWrapper style={{ marginBottom: 'var(--space-medium)' }}>
            <Video {...firstVideo} />
            <h3>{firstVideo.title}</h3>
          </VideoWrapper>
        </div>
        <Grid className="c-large">
          {videos.map(({ id, video }) => (
            <VideoWrapper key={id}>
              <Video {...video} />
              <h3>{video.title}</h3>
            </VideoWrapper>
          ))}
        </Grid>
      </InView>
      <InView
        onChange={(inView, entry) => handleInView('images', inView, entry)}
      >
        <h2 className="c-large">{t({ id: 'images' })}</h2>
        <GalleryBlock images={gallery} downloadable />
      </InView>
      <InView
        onChange={(inView, entry) => handleInView('press', inView, entry)}
      >
        <h2 className="c-large">{t({ id: 'press' })}</h2>
        <div className="block c-small">
          {press.map(({ quote, source, link }, index) => (
            <Quote key={index}>
              {quote && <q>{quote}</q>}
              <div className="source">
                {link ? (
                  <a href={link} target="_blank" rel="noopener noreferrer">
                    {t({ id: 'read' })} {source} →
                  </a>
                ) : (
                  source
                )}
              </div>
            </Quote>
          ))}
        </div>
      </InView>
    </>
  )
}

export default MediaPage

export const query = graphql`
  query($locale: DATO_SiteLocale!) {
    dato {
      ...CommonData
      page: pageMedium(locale: $locale) {
        seo: _seoMetaTags {
          ...TagRecordFragment
        }
        slug
        title
        gallery {
          id
          url
          filename
          ...ImageFragment
        }
        press {
          quote
          source
          link
        }
      }
      allVideos(locale: $locale, orderBy: position_ASC) {
        id
        video {
          ...VideoFragment
        }
      }
    }
  }
`
